import React, { Component } from 'react';
import cssObj from './Show.scss';
import Api from '@/static/util/Api.js';
import '@/App.scss';

class Show extends Component {
    //首页底部
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: Api.config.imgUrl,
            status: false,
            imgMain: '',
        };
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    show(type) {
        this.setState({
            imgMain: type + '.jpg',
            status: true,
        });
    }

    hide() {
        this.setState({
            status: false,
        });
    }

    render() {
        return (
            <div className={cssObj.mask_out} onClick={() => this.hide()} style={{ display: this.state.status ? 'block' : 'none' }}>
                <img src={this.state.imgMain === '' ? '' : this.state.imgUrl + this.state.imgMain} alt="" className={cssObj.img_mid} />
            </div>
        );
    }
}

export default Show;
