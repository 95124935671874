import React, { Component } from 'react';
import Api from '@/static/util/Api.js';
import cssObj from './Nav.scss';
import iconFont from '@/static/iconfont/iconfont.scss';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';
import { ReactComponent as Logo } from './logo.svg';
import PropTypes from 'prop-types';

class Nav extends Component {
    //导航
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: Api.config.imgUrl,
            pc_input_show: false,
            moblie_extend: false, //手机端菜单展开
            search_text: '', //搜索字段
            navArray: [
                {
                    content: 'Work',
                    link: 'case',
                },
                {
                    content: 'Service',
                    link: 'service',
                },
                {
                    content: 'About',
                    link: 'about',
                },
                {
                    content: 'Contact',
                    link: 'contact',
                },
            ],
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleEnterKey);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEnterKey);
    }

    handleEnterKey = e => {
        if (e.keyCode === 13 && this.state.pc_input_show) {
            this.do_search();
        }
    };

    change_extend() {
        this.setState({
            moblie_extend: !this.state.moblie_extend,
        });
    }

    click_link() {
        this.setState({
            moblie_extend: false,
        });
    }

    input_action() {
        if (!this.state.pc_input_show) {
            this.setState({
                pc_input_show: true,
            });
        } else {
            this.do_search();
        }
    }

    inputChange = event => {
        this.setState({
            search_text: event.target.value,
        });
    };

    do_search() {
        var search = this.state.search_text;
        if (search === '') {
            message.warning({ content: '请填写搜索内容' });
        } else {
            this.click_link();
            this.props.history.push({ pathname: '/search/for=' + search });
            this.setState({
                search_text: '',
            });
        }
    }

    goPage(link) {
        this.props.history.push('/' + link);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        const nav_item = this.state.navArray.map((item, index) => {
            let opacityBool = false;
            if (this.props.location.pathname === '/detail' && item.link === 'case') {
                opacityBool = true;
            }
            return (
                <div onClick={() => this.goPage(item.link)} style={'/' + item.link === this.props.location.pathname || this.props.location.pathname === '/' || opacityBool ? { opacity: 1 } : { opacity: 1 }} className={'/' + item.link === this.props.location.pathname || opacityBool ? `${cssObj.nav_item} ${cssObj.nav_itemActive} navClass` : `${cssObj.nav_item} ${cssObj.nav_itemCommon} navClass`} key={index}>
                    {item.content}
                </div>
            );
        });

        return (
            <Fade>
                <div onClick={() => this.change_extend()} className={cssObj.nav_mask} style={this.state.moblie_extend ? { display: 'block' } : { display: 'none' }}></div>
                <div className={cssObj.nav_container}>
                    <Link target="_self" to="/" className={cssObj.nav_logo}>
                        <Logo className={cssObj.nav_left} />
                    </Link>
                    <div className={`out ${cssObj.nav_out}`}>
                        <div className={cssObj.nav_mid}>{nav_item}</div>
                        <div className={cssObj.menu} onClick={() => this.change_extend()}>
                            <i className={`${iconFont.iconfont} ${this.state.moblie_extend ? iconFont.icon_close : iconFont.icon_caidan}`}></i>
                        </div>
                    </div>
                    <div className={cssObj.moblie_nav} style={this.state.moblie_extend ? { display: 'block' } : { display: 'none' }}>
                        <div className={cssObj.mobile_link} onClick={() => this.click_link()}>
                            {nav_item}
                        </div>
                    </div>
                </div>
            </Fade>
        );
    }
}

export default withRouter(Nav);
