const config = {
    imgUrl: 'https://alicdn.adtiger.cn/ttgpower/img/', //图片域名
    // imgUrl: '', //图片域名
    uploadUrl: '', //上传的图片域名
    // uploadUrl: 'http://new.www.ttgpower.com/sys/', //上传的图片域名
    // postUrl: 'http://new.www.ttgpower.com/sys/', //接口域名
    postUrl: 'https://www.ttgpower.com/sys/', //接口域名
};

module.exports = {
    config: config,
    getCaseList: 'api/getCaseList', // 获取案例列表
    getCaseDetail: 'api/getCaseDetail', //获取案例详情
    getBannerList: 'api/getBannerList', //获取banner
    getAdvertiseList: 'api/getAdvertiseList', //获取招聘信息
    getCustomerList: 'api/getCustomerList', //获取客户信息
    contactsInfo: 'api/contactsInfo', //联系信息
    getTagList: 'api/getTagList', //获取搜索关键字
};
