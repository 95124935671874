import { notification } from 'antd';
import axios from 'axios';
import qs from 'qs';
import Api from './Api';

let $http = { post: '' };
const key = 'updatable';

$http.post = function (api, data, callback) {
  var URL = process.env.NODE_ENV === 'development' ? api : Api.config.postUrl + api;
  let params = qs.stringify(data);
  let fn = callback || function () {};
  axios.post(URL, params).then(res => {
    if (res.data.code === 1) {
      fn(res.data);
      // notification['success']({
      //   key,
      //   message: '提示',
      //   description: '加载完成',
      //   duration: 0.5,
      // });
    } else {
      var text = res.data.message ? res.data.message : '接口出错';
      notification.open['error']({
        key,
        message: '提示',
        description: text,
        duration: 0.5,
      });
    }
  });
};

export default $http;
