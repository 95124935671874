import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import $http from './static/util/Axios';
import * as serviceWorker from './serviceWorker';
import FastClick from 'fastclick';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';
import './App.scss';

FastClick.attach(document.body);

React.Component.prototype.$http = $http;

ReactDOM.render(
    <Router>
        <App className="App" />
    </Router>,
    document.getElementById('root')
);

serviceWorker.unregister();
