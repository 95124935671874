import React, { Suspense, lazy, Component } from 'react';
import { Route, Switch, Redirect, withRouter, BrowserRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import Nav from '@/component/Nav/Nav.jsx';
import Show from '@/component/Show/Show.jsx';
import '@/App.scss';
import './static/self.less';

const Index = lazy(() => import('@/pages/Index/Index.jsx')), //首页
    Case = lazy(() => import('@/pages/Case/Case.jsx')), //案例
    About = lazy(() => import('@/pages/About/About.jsx')), //关于我们
    Service = lazy(() => import('@/pages/Service/Service.jsx')), //核心业务
    Contact = lazy(() => import('@/pages/Contact/Contact.jsx')), //联系我们
    Detail = lazy(() => import('@/pages/Detail/Detail.jsx')), //案例详情
    Search = lazy(() => import('@/pages/Search/Search.jsx')); //搜索结果

class App extends Component {
    static childContextTypes = {
        showImg: PropTypes.func,
    };

    UNSAFE_componentWillReceiveProps() {
        this.windowScrollTop();
    }

    componentDidMount() {
        this.windowScrollTop();
    }

    windowScrollTop() {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 100);
    }

    showImg(type) {
        this.child.show(type);
    }

    getChildContext() {
        return {
            showImg: this.showImg.bind(this),
        };
    }

    onRef = ref => {
        this.child = ref;
    };

    render() {
        return (
            <BrowserRouter>
                <Nav />
                <Show onRef={this.onRef} />
                <Suspense fallback={<Spin tip="加载中..." className="wait_loading" />}>
                    <Switch>
                        <Route exact path="/" component={Index}></Route>
                        <Route path="/case" component={Case}></Route>
                        <Route path="/about" component={About}></Route>
                        <Route path="/service" component={Service}></Route>
                        <Route path="/contact" component={Contact}></Route>
                        <Route path="/detail" component={Detail}></Route>
                        <Route path="/search" component={Search}></Route>
                        <Redirect to="/" />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        );
    }
}

export default withRouter(App);
